import React from 'react';
import './components/css/style.css';
import Routes from './components/Routes';


function App(props) {
  // const { classes } = props;
  return (
      // <Grid container className={classes.root}>
          <Routes />
      // </Grid>
  );
}

// App.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// export default withStyles(styles)(App);
export default App